import React from "react" 
const MenuComponent = ({page,setPage}:any) => { 

    let classAddon='steps--row__box focus active';
    if(page==5 || page==6){
        classAddon='steps--row__box focus active-half'
    }
    const pageChange = (page_id:any) => {
            if(page>page_id){
                setPage(page_id);
            }
    } 
    let plans_class=(page==4)?' purple-dot':'purple-dot';
    let addon_class=(page==7)?' purple-dot':'';
    let contact_class=(page==8)?' purple-dot':'';
    let checkout_class=(page==9)?' purple-dot':''; 
    return (<><div className="steps--row"> 
    <div  className={page>4?classAddon:'steps--row__box focus'+plans_class} onClick={() => {pageChange(4)} }>
        <span className="steps--row__box-cont active">Plan</span>
    </div>
    <div className={page>7?'steps--row__box focus active':'steps--row__box focus'+addon_class} onClick={() => {pageChange(7)} }>
        <span className="steps--row__box-cont">Add-ons</span>
    </div>
    <div className={page>8?'steps--row__box focus active':'steps--row__box focus'+contact_class} onClick={() => {pageChange(8)} }>
        <span className="steps--row__box-cont">Contact</span>
    </div>
    <div className={page>9?'steps--row__box focus active':'steps--row__box focus'+checkout_class} onClick={() => {pageChange(9)} }>
        <span className="steps--row__box-cont">Checkout</span>
    </div>
</div>
</>)
}

export default MenuComponent;